@import url('../../core.css');

.footer-container {
    display: flex;
    width: 100%;
    padding: 30px 0px;
    /* background-color: #E7FCF7; */
    background-color: var(--primary-gray);
    justify-content: center;
    /* border-top-left-radius: 100px; */
    /* border-top: 0px */
}

.footer {
    width: 100%;
    padding: 0px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-text-container {
    display: flex;
    flex-direction: column;
    max-width: var(--desktop-col-6);
}

.footer-copyright {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    font-weight: 700;
    color: #656D79;
}

.footer-logos {
    display: flex;
    gap: 30px;
    justify-content: center;
    width: var(--desktop-col-6);
}

.footer-payment-methods {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #d4e6e0;
    width: 100%;
    flex-wrap: wrap;
}

.payment-logo {
    width: auto;
    height: auto;
}

@media (max-width: 767px) {
    .footer {
        flex-direction: column;
    }

    .footer-return {
        margin-bottom: 10px;
    }

    .footer-discount_disclaimer,
    .footer-copyright,
    .footer-text-container {
        text-align: center !important;
    }
    
    .footer-logos {
        flex-wrap: wrap;
        gap: 15px;
        max-width: 80%;
    }
    
    .footer-logo {
        padding: 0 10px;
    }
    .footer-payment-methods {
        max-width: 350px;
        flex-wrap: wrap;
    }
}