.expand {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 0px;
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-thickness: Auto;
  text-decoration-skip-ink: auto;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--primary-lightgreen, #11ddac);
}

.product-image {
    margin-right: 15px;
    aspect-ratio: 1 / 1;
    margin-right: 15px;
    max-width: 35px;
    max-height: 35px;
    object-fit: cover;
}