.support-section {
    margin-top: 38px;
}

.support-accordion-container {
    background-color: var(--primary-white, white);
}

.support-accordion-item {
    box-shadow: 0px -1px 0px 0px #D9E3D7 inset;
}

.support-accordion-title {
    padding-left: 1em;
    padding-right: 1em;
}

.support-accordion-content.open {
    padding-left: 1em;
}