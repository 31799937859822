.autocomplete-results {
    position: absolute;
    width: calc(100% - 20px); /* Adjusted width to fit within search bar */
    background: white;
    border: 1px solid #ddd;
    max-height: 200px;
    overflow-y: auto;
    list-style: none;
    padding: 0;
    margin: 0 10px; /* Keep horizontal margin */
    z-index: 10;
    border-radius: 6px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    top: 100%; /* Ensures it appears below the input field */
    left: 0;
}

.autocomplete-item {
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    color: var(--primary-blue);
}

.autocomplete-item:hover,
.autocomplete-item.selected {
    background: #f0f0f0;
}

.highlight {
    font-weight: bold;
}
