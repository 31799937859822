@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800&family=Roboto:wght@300;400;500;700&display=swap");



*,
::after,
::before {
  padding: 0;
  margin: 0;
  line-height: 150%;
  box-sizing: border-box;
  font-family: Poppins, sans-serif;
}


img {
  user-select: none;
}

.page-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page-content-container {
  padding: 30px;
  padding-top: 60px;
  display: flex;
  background-color: var(--primary-gray);
  flex-direction: column;
  align-items: center;
  flex: 1;
}

@media (max-width:764px) {
  .page-content-container{
    padding-top: 30px;
  }
  
}


:root {
  --primary-white: white;
  --primary-black: black;
  --primary-green: #e7fcf7;
  --primary-blue: #0d2c54;
  --primary-lightgreen: #11ddac;
  --primary-darkgreen: #00B086;
  --primary-gray: #f7f7f7;
  --sub-lightgreen: #3df5ca;
  --sub-darkgreen: #0A9281;
  --primary-info: rgba(232, 241, 255, 1);
  --sub-info: rgba(75, 145, 250, 1);
  --primary-danger: #ef4747;
  --sub-danger: #ffebeb;
  --primary-warning: #fe9f2f;
  --sub-warning: #fff3e6;
  --primary-success: #19d845;
  --sub-success: #e8fbec;
  --sub-gray: #dbe1eb;
  --secondary-green:#0A9281;
  --radiobutton-text-color: #656d79;
  --questionnaire-scrollbar-gray: #9295a0;
  --questionnaire-select-placeholder: #98a0af;
  --desktop-container-padding: 0px 15px;
  --desktop-col-12: 1140px;
  --desktop-col-10: 920px;
  --desktop-col-8: 730px;
  --desktop-col-6: 540px;
  --desktop-col-5: 445px;
  --desktop-col-4: 350px;
  --tablet-container: 100%;
  --tablet-container-padding: 0px 30px;
  --mobile-container-padding: 0px 15px;
  --inactive-gray: #A9A9A9;
  --order-details-cancel: #b4b4b4;
}


/* A tags override  */
#contact-form-description-link {
  color: var(--primary-lightgreen) !important;
}

#contact-form-description-link:visited { 
  color: var(--primary-lightgreen) !important;
}

#contact-form-description-link:visited:hover, #contact-form-description-link:hover {
  text-decoration: none;
  cursor: pointer;
}




/* End of A tags override */


@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.hover-card-content-delay {
  opacity: 0;
  animation: fade-in 0.15s ease-in 0.25s forwards; /* Delay 0.25 for the hover */
}
