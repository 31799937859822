.timeline {
  display: grid;
  grid-auto-flow: column;
  margin-bottom: 10px;
}

.timeline-contents-container {
  justify-items: center;
  gap: 0.3em;
}

.line-and-circle-container {
  --circle-diameter: 35px;
  --gap-between-line-and-circle: 2px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.timeline-checkpoint-circle {
  color: var(--primary-white, white);
  font-family: "Roboto", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "wdth" 100;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
}

.timeline-central-line {
  height: 2px;
  top: 50%;
  width: calc(
    50% - var(--circle-diameter) / 2 - var(--gap-between-line-and-circle)
  );
  transform: translateY(-50%);
  position: absolute;
}

.timeline-central-line.left {
  right: calc(
    50% + var(--gap-between-line-and-circle) + var(--circle-diameter) / 2
  );
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.timeline-central-line.right {
  left: calc(
    50% + var(--gap-between-line-and-circle) + var(--circle-diameter) / 2
  );
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  z-index: 1;
}

.timeline-contents-container:last-child
  .line-and-circle-container
  .timeline-central-line.right {
  display: none;
}

.timeline-contents-container:first-child
  .line-and-circle-container
  .timeline-central-line.left {
  display: none;
}

.danger-sign {
  --sign-size: 17px;

  position: absolute;
  border-radius: 100%;
  height: var(--sign-size);
  width: var(--sign-size);
  font-size: var(--sign-size);
  background-color: var(--primary-danger, #f73333);
  right: 0;
  top: 50%;
  transform: translate(50%, -50%);
}

.danger-sign::before,
.danger-sign::after {
  content: "";
  background-color: var(--primary-white, white);
  width: 0.07882352941em;
  height: 0.6696717163em;
  position: absolute;
  border-radius: 1em;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%) rotate(-45deg);
}

.danger-sign::after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.danger-sign::before {
  transform: translate(-50%, -50%) rotate(-45deg);
}

/* bottom */
.timeline-checkpoint-title {
  font-family: "Poppins", serif;
  text-align: center;
  text-wrap: wrap;
  font-size: 12px;
  max-width: 74px;
  font-weight: 500;
  line-height: 12.36px;
  margin-top: 2px;
}

/* top */
.timeline-checkpoint-date {
  font-family: "Poppins", serif;
  font-weight: 600;
  font-style: normal;
  font-size: 12px;
  text-align: center;
  max-height: 18px;
}

@media (max-width: 764px) {
  .timeline {
    overflow-x: auto;
    overflow-y: hidden;
  }
  
  .timeline::-webkit-scrollbar {
    display: none;
  }
}

/* Keep existing color and state classes */
.timeline-checkpoint-circle.inactive {
  background-color: var(--inactive-gray, #a9a9a9);
}

.timeline-checkpoint-circle.active {
  background-color: var(--sub-darkgreen, #0a9281);
}

.right.active,
.left.active {
  background-color: var(--sub-darkgreen, #0a9281);
}

.right.inactive,
.left.inactive {
  background-color: var(--inactive-gray, #a9a9a9);
}

.timeline-checkpoint-date.active {
  color: var(--sub-darkgreen, #0a9281);
}

.timeline-checkpoint-date.inactive,
.timeline-checkpoint-title.inactive {
  color: var(--inactive-gray, #a9a9a9);
}

.timeline-checkpoint-title.active {
  color: var(--primary-black, #121212);
}
