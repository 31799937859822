.toggle-radio-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 25px;
  width: 42px;
  padding: 3px;
  border-radius: 13px;
  background-color: #11ddac;
  cursor: pointer;
}

.toggle-radio-button-widget-left {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: white;
  transition: all 0.3s;
}

.toggle-radio-button-widget-right {
  transform: translateX(20px);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: white;
  transition: all 0.3s;
}

.opaque {
  background-color: #E5E5E5;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}


.pulsing {
  animation: pulse 1s infinite;
}