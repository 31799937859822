.my-account-accordion {
  width: 100%;
}

.my-account-accordion-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  cursor: pointer;
}

.my-account-accordion-content {
  flex-direction: row;
  max-height: 0;
  overflow: hidden;
  gap: 20px;
  padding: 0 50px 0 10px;
}

.my-account-accordion-button {
  margin-top: 10px;
}

.my-account-accordion-content.open {
  max-height: fit-content;
  padding: 0 50px 14px 10px;
}

@media screen and (max-width: 768px) {
  .my-account-accordion-content {
    flex-direction: column;
    max-width: fit-content;
  }
}
