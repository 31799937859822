.card {
  background-color: #fafbf4;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin-top: 30px;
  max-width: 825px;
  padding-right: 18px;
}

.card::before {
  content: "";
  width: 5px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 1em 0 0 1em;
}

.card.danger::before,
.card.cancel::before {
    background-color: var(--primary-danger, #ef4747);
}

.card.success::before {
    background-color: var(--primary-success, #19d845);
}

.card.warning::before {
    background-color: var(--primary-warning, #fe9f2f);
}

.card.info::before {
    background-color: var(--sub-info, #4b91fa);
}


.contentWrapper {
  display: flex;
  padding: 16px;
}

.mainContent {
  flex-grow: 1;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 783px;
  max-height: 22px;
}

.titleContainer {
  display: flex;
  align-items: center;
  max-width: 528.00390625;
  max-height: 20;

  gap: 6px;
}

.warning-icon {
  width: 22px;
  height: 22px;
  overflow: hidden;
}

.title {
  font-family: "Poppins", serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 19.6px;
  letter-spacing: 0px;
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-thickness: Auto;
  color: var(--primary-black, black)
}

.toggleButton {
  font-family: "Poppins", serif;
  font-weight: 400;
  font-style: italic;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: right;
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-thickness: Auto;
  text-decoration-skip-ink: auto;
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
}

.arrow {
  width: 16px;
  height: 16px;
  margin-left: 4px;
  transform: rotate(0deg);
  transition: transform 0.2s ease;
}

.arrow.expanded {
  transform: rotate(180deg);
}

.notificationBody {
    position: relative;
    max-width: 761px;
    left: 24px;
    font-family: "Poppins", serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.6px;
    letter-spacing: 0px;
    color: var(--primary-black, black)
}

.notificationBody.undertitle{
  margin-top: 15px;
}

.interactable {
    color: var(--sub-darkgreen, #0a9281);
}