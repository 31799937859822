.hamburger-menu,
.hamburger-menu-show {
  display: flex;
  flex-direction: column;
  z-index: 1500;
  background: #fff;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: 220px;
  overflow: auto;
  opacity: 1;
  box-sizing: border-box;
  transition: all 0.6s;
  transform: translateX(100%);
}

.hamburger-menu-show {
  transform: translateX(0%);
}

.hamburger-menu-head {
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #eef1f6;
}

.hamburger-menu-head .nav-search,
.hamburger-menu-head .nav-shopping-bag,
.hamburger-menu-head .cms-header-hamburger,
.hamburger-menu-head .nav-user,
.hamburger-menu-head .treatments-dropdown-modal-active{
  display: none;
}



.hamburger-menu-head .cms-header-navigation{
  position: absolute;
  right: 0;
}

.hamburger-menu-head img {
  cursor: pointer;
}

.doktor-logo {
  width: 135px;
  height: 22px;
}

.hamburger-menu-treatments {
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;
}

.hamburger-menu-treatments .root-category-accordion {
  padding: 15px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0 !important;
  flex-direction: column;
}


.hamburger-menu-treatments .root-category-accordion .root-category-title,
.hamburger-menu-treatments .root-category-accordion .root-category-title-active
{
  margin: 0;
  color: var(--primary-blue);
  font-weight: 500;
}

.hamburger-menu-treatments .root-category-accordion .root-category-title-active{
    color: var(--primary-lightgreen);
}


.hamburger-menu-treatments .root-category-accordion h4:hover {
  cursor: pointer;
  color: var(--primary-lightgreen);
  transition: all 200ms ease-in-out;
}

.hamburger-menu-treatments .root-category-accordion .chevron-down,
.hamburger-menu-treatments .root-category-accordion .chevron-up {
  margin: 0;
}

.hamburger-menu-treatments .root-category-accordion-head {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.hamburger-menu-treatments .child-categories {
  list-style-type: none;
  width: 100%;
  margin-top: 15px;

}

.hamburger-menu-treatments .child-category {
  padding: 12px 0px;
}

.hamburger-menu-treatments .child-category span:first-child {
  text-decoration: underline;
  margin-inline-end: 0.5em;
}

.hamburger-menu-treatments .child-category .has-menu-sticker {
  text-decoration-color: var(--primary-darkgreen) !important;
  text-decoration-thickness: 2px !important;
}

.hamburger-menu-treatments .child-category a {
  font-size: 14px;
  color: var(--radiobutton-text-color);
  text-decoration: none;
}

.hamburger-menu-treatments .child-category a:hover {
  text-decoration: underline;
}

.hamburger-menu-footer-link {
    flex:0.3;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 42px;
    color: var(--primary-lightgreen);
    font-size: 10px;
}

.hamburger-menu-footer-link{
    font-weight: 500;
}

.hamburger-menu-footer-link img {
    width: 42px;
    margin-bottom: 5px;
}
.hamburger-menu-links{
    margin-bottom: 65px ;
    flex: 1;
}

.hamburger-menu-link{
    padding: 0px 30px;
    margin-bottom: 20px;
}

.hamburger-menu-link a {
    cursor: pointer;
    text-decoration: none;
    color: var(--radiobutton-text-color);
    font-size: 13px;
    font-weight: 500;
}

.hamburger-menu-footer{
    display: flex;
    justify-content: center;
    padding: 30px;
    background-color: var(--primary-green);
    border-top-left-radius: 30px;
}


@media (max-width:764px){
    .hamburger-menu,
    .hamburger-menu-show {
        left: 50px;
    }

    .hamburger-menu-head .cms-header-navigation{
      right: 20px;
    }

}