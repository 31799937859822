.user-details-hover-connected {
  position: absolute;
  display: flex;
  flex-direction: column;
  top:25px;
  left:-150px;
  transform: translateX(-50%);
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 16px;
  width: 375px;
  height: 629px;
  z-index: 10;
} 
.background-header {
    width: 100%;
    height: 136px; 
    background-color: var(--primary-gray);

    border-radius: 10px 10px 10px 10px; 
    position: absolute;
    top: 0;
    left: 0;
    z-index:-1;
}
.connected-user-flexbox-top{
    display: flex;

    flex: 20%;
}
.connected-user-flexbox-buttom{
  margin-top: 10px;

    display: flex;

    flex: 80%;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 14px;
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
  z-index: 12 ;
}

.user-info {
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding-bottom: 12px;
  margin-bottom: 12px;
}


.user-avatar {
  width: 58.03px;
  height: 58.03px;
  border-radius: 50%;
  background-color: var(--secondary-green);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 29.57px;
  font-family: 'Roboto', sans-serif;
  margin-right: 12px;
  padding-top: 4px;
}

.user-text .user-name {
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

.user-text .user-email {
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  color: #656D79;
  font-weight: 400;
}
.bottom-border{
    border-bottom: 1px solid #ddd;
}


.user-menu li {
  padding:10px;
  margin-left: 0px;

  font-size: 17px;
  font-family: 'Roboto', sans-serif;
}
.user-menu .card-link {
  display: flex;
  align-items: center; 
  text-decoration: none;
  font-size: 18px;
  color: #656D79;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}

.user-menu img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.fr-card {
  height: 520px;
}

@media (max-width: 764px) {

  .user-details-hover-connected {
    top:25px;
    left: min(40vw,160px);
    width:min(90vw,308px);
    height: 609px;
  }
  .user-details-hover-connected.fr-card {
    height: 569px; 
  }
  .background-header {
    height: 23.809%; 
  }
  .mobile-bottom-bar {
    width: 100%;
    height: 64px; 
    background-color: var(--primary-gray);
    border-radius: 10px 10px 10px 10px;
    position: absolute;
    top: 1;
    left: 0;
    z-index: -1;
  }
  .log-out{
    margin-top:10px ;
  }
  .connected-user-flexbox-top{

    flex: 23.8%;
}
.connected-user-flexbox-buttom{
  margin-top: 0px;
    flex: 76.2%;
}
.user-menu li{
  padding: 7px;
  color: #656D79;
  font-size: 15px;

}
 .connected-user-flexbox-top {
    justify-content: center; 
  }

  .user-info {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-top: 0px;
  }

  .user-avatar {
    margin-right: 0;
    margin-bottom: 8px;
  }
}
@media (max-width: 297px) {
  .user-details-hover-connected.fr-card {
    height: 617px; 
  }
}
@media (max-width: 250px) {
  .user-details-hover-connected.fr-card {
    height: 690px; 
  }
}
