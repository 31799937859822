.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 1rem;
  }
  
.popup-container {
    background-color: white;
    width: 100%;
    max-width: 360px;
    position: relative;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
.popup-close {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 20px;
    border: none;
    background: none;
    cursor: pointer;
    color: #666;
    padding: 4px;
  }
  
.popup-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
    color: #1a1a1a;
    padding-right: 24px;
    text-align: left;
  }
  
.popup-description {
    color: #666;
    margin-bottom: 32px;
    line-height: 1.5;
    font-size: 14px;
    text-align: left;
  }
  
.popup-buttons {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
.popup-button-primary {
    width: 100%;
    padding: 14px;
    border: none;
    border-radius: 8px;
    background-color: #00D5B3;
    color: white;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    transition: background-color 0.2s;
  }
  
.popup-button-secondary {
    width: 100%;
    padding: 14px;
    border: 1px solid #00D5B3;
    border-radius: 8px;
    background-color: white;
    color: #00D5B3;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    transition: background-color 0.2s;
  }
  
.popup-button-primary:hover {
    background-color: #00C2A3;
  }
  
.popup-button-secondary:hover {
    background-color: rgba(0, 213, 179, 0.05);
  }