
  .disconnected-background-header {
    width: 100%;
    height: 50%;
    background-color: var(--primary-gray);;
    border-radius: 10px 10px 10px 10px; 
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  /* position: relative;
  display: inline-block;
  overflow: visible;  */

  .user-details-hover-container {
    display: inline-block;
    position: absolute;
    top: 36px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9;
  }
  .user-details-hover {
    position: absolute;
    top: 15px;
    left:-180px;
    transform: translateX(-50%);
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 16px;
    text-align: center;
    width: 375px;
    height: 259px;
    z-index: 10 ;

  }
  .disconnected-content-grid-container{
    display: grid;
    grid-template-rows: 60% 30%; 
    height: 100%; 
  }
  .disconnected-grid-row1{
    display: grid;
    grid-template-columns: 20% 80%;
    align-items: center; 
    margin-bottom: 30px;

  }
  .disconnected-grid-row2{
    grid-template-columns: 100;
    align-items: center;
    justify-content: center;
    margin-top:1px;

  }
  
  .user-icon-container {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
  }
  
  .user-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  .user-message {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    color: #656D79;
    margin-bottom: 8px;
    text-align: left; 
    padding-left: 5px; 
  }
  
  .disconnected-login-btn {
    align-items: center;
    justify-content: center;
    background-color:  var(--secondary-green);
    color: white;
    border: none;
    padding: 10px;
    width: 54%;
    height: 72%;
    border-radius: 50px !important;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
  }
  
  .login-icon {
    width: 22px;
    height: 22px;
    margin-right: 8px;
  }
  
  .register-link {
    font-size: 12px;
    margin-top: 8px;
  }
  
  .register-link a {
    color: var(--secondary-green);
    text-decoration: none;
    border-bottom: 1px solid var(--secondary-green); 
    padding-bottom: 2px; 
}

  
@media (max-width: 768px) {

  .user-details-hover {
    width:min(90vw,308px);
    height: 259px;
    left: min(40vw,150px);
    top: 25px;

    transform: translateX(-50%);
    height: auto;
    border-radius: 12px;
    padding: 20px;
  }

  .disconnected-background-header {
    height: 50%; 
    border-radius: 12px 12px 0 0;
  }

  .disconnected-content-grid-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
  }

  .disconnected-grid-row1 {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 20px;
  }

  .user-message {
    font-size: 15px;
    text-align: center;
  }

  .disconnected-login-btn {
    width: min(184px,70vw);
    height: min(46px,70vw);
    padding: 12px;
    font-size: 14px;
  }

  .register-link {
    font-size: 12px;
    margin-top: 12px;
  }

  .register-link a {
    display: block;
    text-align: center;
  }
}
