@import url('../../core.css');

.payment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: var(--primary-gray);
  padding: 60px 0 90px;
  min-height: 100vh;
}

.payment-title{
  font-size: 28px;
  font-weight: 700;
  color: var(--primary-blue);
  margin: 0 0 60px;
  align-self: center;
  width: var(--desktop-col-10);
}

.left-panel > .orderWindow-container-title{
  align-self: flex-start;
}


.adyen-checkout__payment-method__header__title > span:nth-last-child(2) {
  position: absolute;
  right: 0;
}

.payment-main-content {
  display: flex;
  justify-content: space-between;
  width: var(--desktop-col-10);
}

.payment-main-content .left-panel {
  /* margin-top: 60px; */
  width : var(--desktop-col-6) !important
}

#dropin-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.payment-main-content .right-panel {
  background-color: var(--primary-gray);
  margin-top: 0;
  width: 350px;
}


.adyen-checkout__payment-method__name {
  font-size: 14px;
  font-size: .875rem;
  color: var(--primary-blue);
  font-weight: 500;
}


.adyen-checkout__payment-method__header {
  align-items: center;
  color: var(--primary-blue);
  display: flex;
  flex-wrap: nowrap;
  font-size: 1em;
  font-weight: 500;
  justify-content: space-between;
  padding: 16px 16px 16px 48px;
  position: relative;
  transition: background .1s ease-out;
  width: 100%;
}


.adyen-checkout__payment-method--klarna .adyen-checkout__payment-method__image__wrapper, .adyen-checkout__payment-method--klarna .adyen-checkout__payment-method__image__wrapper{
  /* background: url("www.doktorabc.org/img_v4/payment/de_klarna.png") no-repeat 50%/contain; */
}


.adyen-checkout__card__brands{
  display: none;
}

.adyen-checkout__field__exp-date_hint_wrapper{
  display: none;
}


.adyen-checkout__payment-method__brands{ 
}

.adyen-checkout__payment-method__brands:after{
  display: none !important;
}

.adyen-checkout__payment-method__image__wrapper {
  display: block;
}

.adyen-checkout__payment-method:not(.adyen-checkout__payment-method--selected) .adyen-checkout__payment-method__image__wrapper:has(img[alt="Credit or debit card"]) {
  display: none;
}

/* Ensure the logo is visible when selected */
.adyen-checkout__payment-method--selected .adyen-checkout__payment-method__image__wrapper:has(img[alt="Credit or debit card"]) {
  display: inline-block;
}

.adyen-checkout__payment-method--selected {
  border: 3px solid var(--primary-white);
  outline: 1px solid var(--primary-lightgreen);

}

.adyen-checkout__payment-method--selected + .adyen-checkout__payment-method,
.adyen-checkout__payment-method {
  border-radius: 5px !important;
  background-color: var(--primary-white);
  margin-bottom: 10px;
}

.adyen-checkout__payment-method__radio {
  width: 18px;
  height: 18px;
  aspect-ratio: 1 !important;
  background-color:  var(--primary-white);
  border-radius: 50%;
  margin-right: 10px;
  box-sizing: border-box;
  cursor: pointer;
  transition: none !important;
  box-shadow: none !important;
  outline: 2px solid #eef1f6 !important;
  border: none;
}


.adyen-checkout__button--pay {
  background-color: var(--primary-lightgreen);
  border-radius: 3px !important;
  height: 56px;
}

.adyen-checkout__button--pay:hover{
  background-color: #00ca94 !important;
  box-shadow: none !important;
}

.adyen-checkout__button__text {
  margin-left: 5px;
  font-weight: 700;
  color: var(--primary-white);
  font-size: 16px;
  font-size: 1rem;
  text-overflow: clip!important;
  white-space: normal;
  overflow: auto;
}

.adyen-checkout__payment-method__radio--selected {
  width: 18px;
  height: 18px;
  aspect-ratio: 1 !important;
  background-color: var(--primary-lightgreen);
  border: 3px solid var(--primary-white) !important;
  outline: 2px solid var(--primary-lightgreen) !important;
  border-radius: 50%;
  box-sizing: border-box;
  margin-right: 10px;
  transition: none;
}

.adyen-checkout__payment-method__radio::after,
.adyen-checkout__payment-method__radio--selected::after{
  display: none ;
}

.adyen-checkout__input {
  display: flex;
  width: 100%;
  padding: 10px;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  border-radius: 6px;
  overflow: hidden;
  background-color: var(--primary-white);
  border: 1px solid var(--sub-gray);
  font-size: 14px;
  font-weight: 500;
}


.adyen-checkout__label__text {
  font-family: Roboto,sans-serif;
  line-height: 150%;
  font-size: 13px;
  font-size: .8125rem;
  color: var(--radiobutton-text-color);
  font-weight: 700;
  display: block;
  margin: 10px 0 0;
}

.adyen-checkout__field--error .adyen-checkout__input {
  border: 1px solid var(--primary-danger);
}


.adyen-checkout__error-text {
  display: block;
  font-size: 10px;
  font-weight: 500;
  margin-top: 5px;
  color: var(--primary-danger);
}

.adyen-checkout__checkbox__input:checked + .adyen-checkout__checkbox__label::after {
  background-color: var(--primary-lightgreen);
  border: var(--primary-lightgreen);
}


@media (max-width: 768px){

  .payment-container{
    height: 100%;
    padding: 30px 0 60px;
  }

  .payment-title{
    width: 100%;
    font-size: 20px;
    margin-bottom: 25px;
    padding: var(--mobile-container-padding);
  }

  .payment-main-content{
    display: flex;
    flex-direction: column;
    padding: var(--mobile-container-padding);
    width: 100% !important; 
    align-items: center;
  }

  .dropin-container{
    width: 100% !important;
  }

  .payment-main-content > .left-panel{
    width: 100% !important; 
    padding: 0;
  }
  
  .payment-main-content > .right-panel{
    width: 100% !important; 
    margin-top: 25px;
    padding: 0;

  }

  .payment-main-content > .right-panel > .orderWindow-container{
    padding: 0;
   
  }
}



@media (min-width: 768px) and (max-width: 1200px) {


  .payment-container{
    padding: 60px 0px 90px;
    height: 100%;
  }
  
  .payment-title{
    width:var(--desktop-col-8) !important ;
    margin-bottom: 20px;
    padding: 0 15px;
  }
 
  .payment-main-content{
    display: flex;
    flex-direction: column;
    padding: var(--tablet-container-padding);
    width: var(--desktop-col-10) !important; 
    align-items: center;
  }

  .payment-main-content > .left-panel{
    width: var(--desktop-col-8) !important; 
    margin-top: 25px;
    padding: 0 15px;

  }
  
  .payment-main-content > .right-panel{
    width: var(--desktop-col-8) !important; 
    margin-top: 25px;
    padding: 0 15px;

  }

  .payment-main-content > .right-panel > .orderWindow-container{
    padding: 0;
  }


  
}