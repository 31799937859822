@import url("../../core.css");

.copy-icon {
    margin-left: 0.50em;
    margin-bottom: 0.10em;
    transform: rotate(90deg);
}

.copy-icon:hover {
    cursor: pointer;
}

#copy-message {
    position: absolute;
    top: 0;
    left: 100%;
    background-color: var(--questionnaire-scrollbar-gray);
    padding: 2px;
    padding: 4px;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 0.875rem;
    margin-left: 0.5rem;
    color: white;
    border-radius: 0.375rem;
    transition-property: opacity;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1;
}
